@import "../../styles/contants.scss";

.wrapper {
  background: white;
  overflow: hidden;
  .container {
    flex-direction: column;
    gap: 2rem;
    .heading {
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      > :nth-child(2) {
        text-decoration: underline;
        color: orange;
        cursor: pointer;
      }
    }

    .showCase {
      width: 100%;
      gap: 1.5rem;
      justify-content: space-around;
      flex-wrap: wrap;
      > div > img {
        @media (max-width: $sm) {
          width: 100%;
        }
        border-radius: 20px;
        max-width: 25rem;
        max-height: 18rem;
        box-shadow: 0px 21px 13px rgba(0, 0, 0, 13%);
        cursor: pointer;
      }
    }
    .bio {
      > * {
        display: block;
        text-align: center;
      }
      > :nth-child(1) {
        max-width: 20rem;
        overflow-x: wrap;
        font-weight: 600;
        margin-bottom: 5px;
      }
      > :nth-child(2) {
        font-size: 0.7rem;
        color: rgb(172, 172, 172);
      }
    }
  }
}
