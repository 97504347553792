/* stepp zilla styling */

.donation-item {
  display: flex;
  align-items: start;
  margin-bottom: 1rem;
  gap: 1rem;
}

.momoButton {
  border: 1px solid grey;
  border-radius: 1rem;
  padding: 0px;
  overflow: hidden;
}
.momoButton > span {
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: large;
}
.mobileMoneySection {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}
.paypal {
  border: 1px solid grey;
  border-radius: 1rem;
  padding: 0px;
  overflow: hidden;
  height: 100%;
}
.paypal > span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: large;
}
.donation-item .mantine-text-input {
  flex: 1;
  margin-right: 1rem;
}
/* .mantine-Select-dropdown {
  min-width: fit-content !important;
} */

.donation-item > :nth-child(1) {
  flex: 2;
}
.donation-item > :nth-child(2) {
  flex: 1;
}
.btn-add-donation {
  background-color: white;
  color: hsl(122, 38%, 57%);
  border: solid 1.5px hsl(122, 38%, 57%);
  border-radius: 3rem;
  font-size: 10px;
  font-weight: bold;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.btn-add-donation:hover {
  cursor: pointer;
}
.outer-container {
  position: relative;
  width: fit-content;
}
.total-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: end;
  gap: 1rem;
}
.donation-item .mantine-select {
  flex: 1;
  margin-right: 1rem;
}
.outer-coainer-main {
  display: flex;
  justify-content: center;
}

.donation-item .mantine-pill {
  flex: 0;
  margin-left: 1rem;
}
.totalsSection {
  font-size: large;
  font-weight: bold;
  align-self: flex-end;
  display: flex;
}

.donation-item {
  flex-wrap: wrap;
}

.donation-item .mantine-text-input {
  flex: 1 0 100%;
  margin-bottom: 1rem;
  margin-right: 0;
}

.donation-item .mantine-select {
  flex: 1 0 100%;
  margin-right: 0;
}

.donation-item .mantine-pill {
  margin-left: 0;
}

.placeholder-gray::placeholder {
  color: #ac1212;
}
@media (max-width: 576px) {
  .donation-item .mantine-text-input {
    font-size: 0.875rem;
  }

  .donation-item .mantine-select {
    font-size: 0.875rem;
  }

  .donation-item .mantine-pill {
    font-size: 0.875rem;
  }
}
