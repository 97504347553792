@import "./../../styles/contants.scss";

.wrapper {
  position: relative;
  z-index: 200;
  opacity: 1;
  transform: none;
  position: sticky;
  top: 0px;
  transition: all 300ms ease;

  .container {
    position: relative;
    justify-content: space-between;
    font-weight: 500;

    .name {
      font-size: 2rem;
      font-weight: 500;
    }

    .menu {
      @media (max-width: $sm), (max-width: $md) {
        position: absolute;
        list-style: none;
        gap: 2rem;
        font-weight: 500;
        flex-direction: column;
        right: 0;
        top: 3rem;
        height: calc(100vh - 6.3rem);
        width: 50%;
        min-width: 15rem;
        background: white;
        padding: 2rem;
        display: flex;
        border-radius: 10px;
        transition: all 200ms ease;
        align-items: flex-start;
        padding: 3rem;
        justify-content: flex-start;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      }

      text-transform: uppercase;
      font-size: 1rem;
      list-style: none;
      gap: 1.5rem;
      cursor: pointer;

      li {
        a {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          color: $secondary;
        }
      }

      .phone {
        gap: 1.2rem;
        flex-wrap: wrap;
        align-items: center;

        svg {
          color: $secondary;
          background-color: white;
          padding: 10px;
          border-radius: 999px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        }
      }
    }

    .flexGap {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    .leftLogo {
      width: 4rem;
      max-height: 4rem;
    }

    .menuIcon {
      @media (max-width: $sm), (max-width: $md) {
        display: block;
      }

      display: none;
    }
  }
}
