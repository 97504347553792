@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto Medium;
  src: url("fonts/Roboto-Medium.ttf");
}

body {
  margin: 0;
  font-family: Roboto, Arial, sans-serif !important;
  font-weight: 400;
}

.grey {
  color: rgb(97, 97, 97);
}

iframe {
  border: none;
}

/* --- */

/* General */
.grey-color {
  color: rgb(97, 97, 97);
}

.border-bottom {
  border-bottom: 1px solid;
  border-color: rgb(211, 211, 211);
}

svg {
  fill: rgb(95, 95, 95);
  /* max-height: 24px;
  max-width: 24px; */
}

a {
  text-decoration: none;
}

/*  */

/* Header */
.header {
  display: grid;

  top: 0px;
  right: 0px;
  left: 0px;

  z-index: 999;
  background-color: white;
  position: fixed;

  padding: 8px;
  height: 40px;

  box-shadow: 0 5px 0 0 rgb(250, 250, 250);
}

.header-left {
  display: flex;
  margin: 0;
}

.header-left a {
  margin: 0;
  height: 24px;
  width: 24px;
  padding: 8px;
  align-self: center;
}

#logo {
  padding-left: 0;
}

.header-left a img {
  height: 100%;
  width: auto;
}

.search {
  padding: 8px;
}

.search form {
  height: 32px;
  margin: 0 40px;
  display: grid;
  grid-template-columns: auto 65px;
  justify-content: stretch;
}

.search form input {
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid rgb(177, 177, 177);
  border-radius: 2px 0 0 2px;
  float: left;
}

.search form button {
  border-radius: 0 2px 2px 0;
  background: rgb(247, 247, 247);
  border: 1px solid rgb(175, 175, 175);
  border-left: none;
  /* Prevent double borders */
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

.search form button a {
  margin: 0;
  height: 20px;
  width: 20px;

  align-self: center;
}

.search form button a svg {
  height: 20px;
  width: 20px;
}

.header-right {
  grid-column: 3;
  grid-row: 1;
  display: flex;
  margin: 0;
  height: 40px;
  align-self: center;
  justify-self: right;
}

.header-right a {
  height: 24px;
  width: 24px;
  padding: 8px;
  align-self: center;
  margin-right: 8px;
}

#user {
  height: 32px;
  width: 32px;
  padding: 8px;
}

#user img {
  height: 100%;
  border-radius: 50%;
}

.header-right a img {
  align-self: center;
  margin-bottom: 10%;
  height: 120%;
  width: auto;
}

/* Hidden header */

.wide {
  display: none;
}

/*  */

/* End of Header */

.container {
  padding: 24px;
  margin-top: 56px;
  display: grid;
  grid-template-areas:
    "video "
    "sidebar"
    "comments";
  height: 100%;
}

/* Video section */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-title {
  font-size: 9px;
  padding-top: 8px;
}

.video-title > div {
  display: grid;
  grid-template-columns: auto auto;
}

.video-title div div {
  min-height: 30px;
  display: grid;
  grid-template-columns: auto auto;
}

.video-title h1 {
  font-size: 18px;
  font-weight: normal;
}

.like-share {
  grid-row: 1;
  grid-column: 2;
  justify-self: right;
  grid-gap: 24px;
}

.like {
  border-bottom: 2px solid;
  border-color: grey;
  padding-bottom: 8px;
  grid-gap: 16px;
}

#share {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
}

.video-title span {
  font-size: 16px;
  color: grey;
}

.like-share span {
  font-size: 13px;
  font-weight: normal;
  color: rgb(116, 116, 116);
}

.views span {
  color: #606060;

  height: 24px;
}

.like-share a {
  height: 24px;
  display: flex;
  grid-gap: 2px;
  align-items: center;
}

.like-share a svg {
  margin-right: 8px;
  fill: rgb(97, 97, 97, 0.6);
}

#report {
  display: none;
}

.video-info {
  margin-bottom: 24px;
  padding-bottom: 16px;
  display: grid;
  grid-template-rows: 67px auto;
}

.owner {
  display: grid;
  grid-template-columns: 64px 1fr 1fr;
  align-items: center;
  padding-top: 16px;
}

.owner-icon img {
  border-radius: 50%;
}

.owner-name h1 {
  font-family: Roboto Medium;
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
}

.owner-name p {
  padding: 2px 0;
  font-size: 13px;
  margin: 0px;
}

.subscribe {
  justify-self: right;
  background-color: red;
  height: 37px;
  width: 140px;
  border-radius: 2px;
  display: grid;
  justify-content: center;
}

.subscribe a {
  border-radius: 2px;
  color: white;
  text-decoration: none;
  align-self: center;
  font-size: 14px;
  font-family: Roboto Medium;
}

.subscribe a span {
  color: rgba(255, 255, 255, 0.85);
}

.description {
  margin-top: 14px;
  margin-left: 64px;
}

.description p {
  margin: auto;
  font-size: 14px;
}

.description a {
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  color: rgba(17, 17, 17, 0.6);
}

/* Sidebar */

.sidebar {
  margin-bottom: 16px;
}
.next {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: auto auto;
  font-weight: 400;
}

.next h3 {
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.next div {
  align-items: center;
  display: grid;
  grid-template-columns: auto 36px;
}

.next div h4 {
  margin: 0;
  align-self: center;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  font-family: Roboto Medium;
  text-align: right;
}

.next div label {
  align-self: center;
}

/* toggle */
.switch {
  position: relative;
  width: 100%;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -3px;
  background-color: grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 7px;
}

.slider.round:before {
  border-radius: 50%;
}

/*  */

.break {
  padding: 2px;
  margin-bottom: 16px;
}

.thumbnail-container {
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 168px auto;
}

.thumbnail a img {
  width: 168px;
  height: 94px;
}

.thumbnail-info {
  margin-left: 8px;
  padding-right: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnail-info span {
  font-family: Roboto Medium;
  font-size: 14px;
}

.thumbnail-info p {
  font-size: 13px;
  margin: 4px 0;
}

/* Comments */

.sort {
  display: flex;
  height: 24px;
  margin-bottom: 24px;
}

.sort h2 {
  font-size: 16px;
  margin: 0;
  margin-right: 32px;
  padding-top: 2px;
  font-weight: normal;
}

.sort a {
  margin-right: 10px;
}

.sort a svg {
  fill: rgb(145, 145, 145);
}

.sort div {
  color: rgb(138, 138, 138);
  font-size: 15px;
  font-family: Roboto Medium;
  padding-top: 4px;
}

.comments div {
  display: flex;
  margin-bottom: 16px;
}

.comments div a {
  height: 40px;
  width: 40px;
  margin-right: 16px;
}

.comments div a img {
  max-width: 40px;
  height: auto;
  border-radius: 50%;
}

.add-comment {
  color: grey;
  font-size: 14px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: rgb(192, 192, 192);
}

/* Responsive media query */

@media screen and (min-width: 1000px) {
  .container {
    grid-template-areas:
      "video sidebar"
      "comments sidebar";
    grid-template-columns: 640px auto;
    grid-template-rows: auto 1fr;
  }

  .sidebar {
    margin-bottom: 0;
    margin-left: 24px;
    grid-area: sidebar;
  }

  .header {
    padding: 8px 16px;
    grid-auto-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1115px) {
  .container {
    max-width: 1650px;
    margin: 56px auto;
    grid-template-columns: 1fr 402px;
  }

  .search {
    width: 640px;
    margin: auto;
  }
}

/* mobile */
@media screen and (max-width: 570px) {
  .description {
    display: none;
  }
  .video-info {
    padding: 0 16px;
  }
  .container {
    padding: 0;
  }

  .owner {
    padding: 0;
  }

  .owner-name h1 {
    font-family: Roboto;
  }

  #reprot-circles {
    display: none;
  }

  .sidebar,
  .comments,
  .sort,
  .video-title {
    padding: 0 16px;
  }

  .video-title h1 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .views span {
    font-size: 14px;
    margin-top: 8px;
  }

  .like-share {
    grid-row: 2;
    grid-column: 1/3;
    width: 100%;
  }

  .like-share div {
    display: flex;
  }

  .like-share a,
  #report {
    height: 60px;
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
  }

  .like-share div a svg {
    margin: auto;
    fill: rgba(17, 17, 17, 0.6);
  }

  .like-share div a span {
    color: rgba(17, 17, 17, 0.6);
  }
  .sort h2 {
    font-family: Roboto Medium;
  }
}

@media screen and (min-width: 780px) {
  .video-title h1 {
    font-weight: normal;
  }
  .header {
    align-content: center;
    grid-template-columns: 160px auto 240px;
  }

  .header-left a {
    margin-right: 16px;
  }

  .wide {
    display: inline;
  }

  .small {
    display: none;
  }
}
