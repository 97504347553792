.socialMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialHeading {
  display: flex;
  gap: 2rem;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: bold;
  /* color: white; */
  font-style: italic;
  justify-content: center;
}

.trendingCards {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
}

.postsCard {
  display: flex;
  flex-direction: column;
  width: 19rem;
  background: #9c9c9c;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  color: white;
  justify-content: space-between;
}

.postsCard:hover {
  background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
}

.postsCard > div {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.postsCard > div:nth-child(3) > div {
  display: flex;
  align-self: flex-end;
  grid-area: 1rem;
  align-items: center;
}

.connectSection {
  color: var(--lightgray);
  font-weight: bold;
  align-self: flex-end;
}
.connectSection > img {
  width: 1rem;
  height: 0.7rem;
}

.connectSection:hover {
  cursor: pointer;
  color: white;
}

.descriptionAndPic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.descriptionAndPic > span {
  width: 70%;
}

.follower > div {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.followername {
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
}

.followername > span:nth-of-type(1) {
  font-weight: bold;
}

.postersName > img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.descriptionAndPic > img {
  width: 70%;
  max-height: 5rem;
  object-fit: cover;
  border-radius: 0.4rem;
}

.f-button {
  padding: 0.2rem 1rem;
}

@media screen and (max-width: 768px) {
  .socialHeading {
    gap: 1rem;
    font-size: 1rem;
    width: 90%;
    align-self: center;
    margin-left: 0.5rem;
  }

  .trendingCards {
    flex-direction: column;
    align-items: center;
  }
}
